import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CartButton from '../Materials/Partials/_CartButton';

import { USER, APIURL, LANG, Loader, SetTitle } from '../../IWWJsCode';

export default function SubmissionFiles() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { projectId } = useParams();
  const { user } = USER();

  let { lang } = LANG();
  let [submission, setSubmission] = useState();

  useEffect(() => {
    if (!user) {
      navigate('/login?rurl=/Submission-Files/' + id + '/' + projectId);
    }

    if (user) {
      // setLoading(true);
      fetch(APIURL(lang + '/userProjects/submission/' + id))
        .then((res) => res.json())
        .then((json) => {
          setSubmission(json.submission)
          // setLoading(false);
        });
    }
    SetTitle('My Projects');
    window.scrollTo(0, 0);
  }, [id, user, lang]);



  let [videoId, setVideoId] = useState();
  let [video, setVideo] = useState();

  useEffect(() => {
    if (videoId && videoId !== '') {
      playVideo(videoId);
    }
  }, [videoId, lang]);

  async function playVideo(_videoId) {

    if (user && user.Active && _videoId && id !== '') {
      fetch(APIURL(lang + `/userProjects/video?id=${_videoId}&submissionId=${id}`))
        .then((res) => res.json())
        .then((json) => {
          if (json.video) {
            setVideo(json.video);
            if (video)
              SetTitle(video.name);
          }

          if (json.error) {

            closeVideo();
          }
        });
    }
  }

  function closeVideo() {
    if (video || videoId) {
      setVideo(null);
      setVideoId(null);
    }
  }

  return user && user.Active ? (
    <>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div className='col-12'>
              <Link className='btn btn-outline-light text-dark' to={'/project/' + projectId}>
                <i className={t('back-arrow') + ' mx-1'}></i>
                {t('Back')}
              </Link>
            </div>
            {submission ? (
              <>

                <div className='col-12'>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <h1>{submission.name}</h1>
                      <h6>{submission.status}</h6>
                      <h6>{submission.instructor}</h6>
                      <div dangerouslySetInnerHTML={{ __html: submission?.summary }}></div>
                    </div>

                    <div class="col-12 col-lg-12">



                      <h3>Files</h3>
                      <div className="d-flex flex-row  mb-3">
                        {submission.files.map((file, indx) => (
                          <>
                            <div key={indx} className={"p-2 border border-dark rounded"}>
                              <span className='float-end'>
                                <a className='btn btn-success' href={file.url}>Download<i className='fas fa-download ms-1'></i></a>
                              </span>
                              <h4 className='me-1 mb-0'>{file.name}</h4>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                    {submission.videos && submission.videos.length ? <>
                      <div className={'col-12 col-lg-12'}>
                        {video != null ?
                          <div className='row' style={{ top: '81px', zIndex: 9 }}>
                            <div className='col-md-12'>
                              <span className='btn btn-sm btn-light w-100' onClick={() => { closeVideo(); }}><i className='fas fa-times'></i></span>
                              {video.url ? (
                                <div className='ratio ratio-16x9'>
                                  <iframe src={video.url} style={{ border: '0' }} allow='encrypted-media' allowfullscreen={'allowfullscreen'}></iframe>
                                </div>
                              ) : (
                                <div className='alert alert-warning'>
                                  <h4 className='alert-heading'>{video.name} in progress.</h4>
                                  <hr />
                                  <p>
                                    It may take some time, if it takes more than 30 minutes, please <Link to={'/contactus'}>inform the administrators</Link>.
                                  </p>
                                </div>
                              )}
                              <h3>{video.name}</h3>
                            </div>
                          </div>
                          : ''}

                        <h3>Videos</h3>
                        <div className="d-flex flex-row  mb-3">
                          {submission.videos.map((video, indx) => (
                            <>
                              <div key={indx} className={"p-2 border border-dark rounded"}>
                                <span className='float-end'>
                                  <button className='btn btn-success' disabled={video?.id == videoId} onClick={() => { setVideoId(video.id) }}>Play<i className='fas fa-play ms-1'></i></button>
                                </span>
                                <h4 className='me-1 mb-0'>{video.name}</h4>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </> : ''}

                  </div>
                </div>
              </>
            ) : (
              <div className='alert alert-light'>
                <p>{t('No Project Info')}</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  ) : (
    ''
  );
}

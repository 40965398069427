/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { createSearchParams, useSearchParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel';

import { APIURL, LANG, USER, Loader, GET_IMAGE_URL, SetTitle } from '../../IWWJsCode';
import { useApiData } from '../../Contexts/ApiDataContext';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import UniversityBox from '../Materials/Partials/_UniversityBox';

export default function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();

  let { setLoading } = Loader();
  let { user } = USER();
  let { lang } = LANG();
  let { universities, popularUniversities } = useApiData();

  let [HomeIntro, SetHomeIntro] = useState();
  let [HomeAbout, SetHomeAbout] = useState();
  let [HomeMajor, SetHomeMajor] = useState([]);

  let [Colleges, SetColleges] = useState([]);
  let [Majors, SetMajors] = useState([]);
  let [Instructors, SetInstructors] = useState([]);

  let [collegeId, SetCollegeId] = useState();
  let [majorId, SetMajorId] = useState();
  let [instructorId, SetInstructorId] = useState();
  let [universityId, SetUniversityId] = useState();

  useEffect(() => {
    SetTitle('Smart Vision For Integrated Academic Services For All University Students');
    setLoading(true);
    GetHomeIntro();
    GetHomeMajor();
    GetHomeAbout();

    GetInstructors();
    getColleges();
    getMajors()
    setLoading(false);
  }, [lang]);

  async function GetHomeIntro() {
    setLoading(true);
    let { data } = await axios.get(APIURL(lang + '/web/HomeIntro'));
    if (data) SetHomeIntro(data);
    setLoading(false);
  }
  async function GetHomeAbout() {
    setLoading(true);
    let { data } = await axios.get(APIURL(lang + '/web/HomeAbout'));
    if (data) SetHomeAbout(data);
    console.log(data);
    setLoading(false);
  }

  async function GetHomeMajor() {
    setLoading(true);
    let { data } = await axios.get(APIURL(lang + '/web/HomeMajors'));
    if (data) SetHomeMajor(data);
    setLoading(false);
  }

  async function GetInstructors() {
    setLoading(true);
    fetch(APIURL(`${lang}/Courses/instructors`))
      .then((res) => res.json())
      .then((json) => {
        SetInstructors(json);
        setLoading(false);
      });
  }

  async function getColleges() {
    //?id=${universityId}
    fetch(APIURL(`${lang}/Universities/Colleges`))
      .then((res) => res.json())
      .then((json) => {
        SetColleges(json);
      });
  }

  async function getMajors() {
    SetMajors([{ id: '', name: 'loading..' }]);
    //?id=${collegeId}&uid=${universityId}
    fetch(APIURL(`${lang}/Universities/Majors`))
      .then((res) => res.json())
      .then((json) => {
        SetMajors(json);
      });
  }

  function submitFilter() {
    const s = $('#searchModal input#input-search').val();
    navigate({
      pathname: "/courses",
      search: createSearchParams({ s: s, m: majorId, c: collegeId, i: instructorId, u: universityId }).toString()
    });
  }

  return (
    <>
      <section className="home">
        <div className="description w-100">
          {HomeIntro && HomeIntro.length ? (
            <>
              <h1 className="title">
                <span className="gradient-text">{HomeIntro.title}</span>
              </h1>
              <p dangerouslySetInnerHTML={{
                __html: HomeIntro.htmlDescription,
              }}></p>
            </>
          ) : (
            ''
          )}
          <div className="card bg-wihte p-3">
            <div className="form-floating">
              <select id='InstructorId' className="form-select mb-2" value={instructorId}
              defaultValue=''
                onChange={(e) => { SetInstructorId(e.target.value); }} >
                <option value="">{t('All')}</option>
                {Instructors && Instructors.length && Instructors.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name} ({el.coursesCount})
                  </option>
                ))}
              </select>
              <label htmlFor="InstructorId">Instructor</label>
            </div>
            <div className="form-floating">
              <select id='UniversityId' className="form-select mb-2" value={universityId}
                defaultValue=''
                onChange={(e) => { SetUniversityId(e.target.value); }} >
                <option selected value="">{t('All')}</option>
                {universities && universities.length && universities.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name} ({el.coursesCount})
                  </option>
                ))}
              </select>
              <label htmlFor="UniversityId">University</label>
            </div>
            <div className='form-floating w-100 m-1'>
              <select id='collegeId' className={'form-select mb-2'} value={collegeId}
                defaultValue=''
                onChange={(e) => { SetCollegeId(e.target.value); }}>
                <option value="">{t('All')}</option>
                {Colleges && Colleges.length && Colleges.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
              <label htmlFor='collegeId'>{t('College')}</label>
            </div>
            <div className='form-floating w-100 m-1'>
              <select id='majorId' className={'form-select mb-2'} value={majorId}
                defaultValue=''
                onChange={(e) => { SetMajorId(e.target.value); }}>
                <option value="">{t('All')}</option>
                {Majors && Majors.length && Majors.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
              <label htmlFor='majorId'>{t('Major')}</label>
            </div>

            <div className="d-flex justify-content-center">
              <Link onClick={() => submitFilter()} className="btn btn-warning text-white px-5 py-2">
                Search
              </Link>
            </div>

          </div>
        </div>
        <div className="users-color-container">
          <span className="item i-1"></span>
          <img className="item i-2" src="/content/ui/images/head1.jpg" alt="img" />
          <span className="item i-3"></span>
          <img className="item i-4" src="/content/ui/images/head2.jpg" alt="img" />
          <img className="item i-10" src="/content/ui/images/head3.jpg" alt="img" />
          <span className="item i-11"></span>
          <img className="item i-12" src="/content/ui/images/head4.jpg" alt="img" />
          <span className="item i-5"></span>
          <span className="item i-9"></span>
          <img className="item i-8" src="/content/ui/images/head5.jpg" alt="img" />
          <span className="item i-7"></span>
          <img className="item i-6" src="/content/ui/images/head6.jpg" alt="img" />
        </div>
      </section>

      {(HomeAbout) ? (
        <>
          <section className="about-sec mb-5">
            <div className="container">
              <div className="row align-items-center">
                {HomeAbout.image && HomeAbout.image.length ?
                  <div className="col-md-5 col-sm-8 m-auto">
                    <img src={GET_IMAGE_URL(HomeAbout.image)} className='about-img' alt={HomeAbout.title} />
                  </div>
                  : ''}

                <div className={HomeAbout.image ? "col-md-7 col-sm-12" : "col-sm-12"}>
                  <div className="my-4">
                    <h6 className="rounded-pill bg-pill d-inline px-3 py-1 text-white">
                      {t('About')}
                    </h6>
                  </div>
                  <div className="about-text">
                    <h3>
                      <span>
                        {HomeAbout.title}
                      </span>
                    </h3>
                    <div dangerouslySetInnerHTML={{ __html: HomeAbout.htmlDescription }}></div>
                    <Link to="about-us" className="btn btn-warning text-white px-5 py-2">
                      {t("Read More")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : ('')}

      {popularUniversities && popularUniversities.length ? (
        <section className="bg-sec-course my-5 p-5">
          <div className="container">
            <div className="row justify-content-center g-3">
              <div className="col-sm-12">
                <div className="">
                  <h6 className="rounded-pill bg-pill d-inline px-3 py-1 text-white">
                    {t('Universities List')}
                  </h6>
                  <div className="about-text my-4">
                    <h3>
                      {t('Universities')}
                      <span>
                        {t('Courses')}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              {popularUniversities.map((univ, idx) => (
                <div key={idx} className="col-lg-3 col-md-4 col-sm-6">
                  <UniversityBox university={univ} />
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : ('')}

      {Instructors && Instructors.length ? (
        <>
          <section className="partner-sec bg-light  py-5">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="">
                    <h6 className="rounded-pill bg-pill d-inline px-3 py-1 text-white">
                      {t("Instructor")}
                    </h6>
                    <div className="about-text my-4">
                      <h3>
                        <span>
                          {t('Our Instructor')}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col">
                  <OwlCarousel className='owl-carousel owl owl-theme' loop margin={10} items='3' autoplay dots dotsEach={true} touchDrag mouseDrag responsive={{ 0: { items: 1, }, 768: { items: 2, }, 1024: { items: 3, } }}>
                    {Instructors.map((el) => (
                      <div key={el.id}>
                        <Link to={"/courses?i=" + el.id} className="text-decoration-none">
                          <div className="card team rounded-0 border-0 p-3">
                            <div className="">
                              <div className="card-image rounded-circle" style={{ backgroundImage: `url(${GET_IMAGE_URL(el.image)})` }}>
                                <img src="/content/ui/images/blank2-1.png" className="w-100" alt={el.name} />
                              </div>
                            </div>
                            <div className="card-texts py-3">
                              <div>
                                <i className="fas fa-book mx-2"></i>
                                <span>{el.coursesCount} {t('Courses')}</span>
                              </div>
                              <h4>{el.name}</h4>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : ('')}
    </>
  );
}
